import React, { Fragment } from 'react';
import documentIcon from 'assets/bf-images/document_icon.svg';
import sepaIconUrl from 'assets/bf-images/sepa.svg';
import billLogo from 'assets/bf-images/rechnungskauf-logo.svg';
import jsonpath from 'jsonpath';
import { isValidIBAN } from 'ibantools';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useWidgetService } from 'Services/widget';
import { FieldType, LayoutLevel, PaymentMethodName } from 'Services/widgets/enums';
import {
  CompleteEmailVerification,
  ContainerFieldItem,
  DocumentsSettings,
  FileUploadSettings,
  ILeadData,
  PremiumSettings,
} from 'Services/widgets/interfaces';
import { IDataFactorsAndVariables } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { StepInfoItem } from '../../../interfaces';
import { RecalculationTariff, useCoreActions } from '../../../DynamicCore';
import { CoreFieldPanel } from '../../core-ui-components/CoreFieldPanel';
import {
  prepareUploadedDocument,
  renderContainerItemClassName,
  preparePayloadForLead,
  renderGridStyles,
  generateCrossPolicyFields,
  systemFields,
  isIgnoreOnRecalculationFieldCore,
  formatFormData,
  getInfoMessage,
  generateRules,
  renderContainerClass,
} from '../../../core-hooks';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import { DownloadLink } from '../../../ui-elements/DownloadLink';
import { CoreTariffInfo } from '../../core-ui-components/CoreTariffInfo';
import { CoreCheckbox } from '../../core-ui-components/CoreCheckbox';
import { COREBFStep, LayoutTypes } from '../../../enums';
import { LeadStatus } from '@cover42/ts-contracts';
import { paymentDataCoreKey } from '../../../CoreBookingFunnel';
import { useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import { CoreLabel } from '../../core-ui-components/CoreLabel';
import { CoreCirclesNavigationMobile } from '../../core-ui-components/CoreCirclesNavigationMobile';
import { FileUpload } from '../../../ui-components/FileUpload';
import { CoreDynamicField } from '../../core-ui-components/CoreDynamicField';
import { isEmpty } from 'lodash';
import { CoreDocumentDownloadInfo } from '../../core-ui-components/summary-views/CoreDocumentDownloadInfo';
import { CoreMultiInsuredObject } from '../../core-ui-components/CoreMultiInsuredObject';
import { getFieldDiff } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { TooltipPopupCore } from 'App/components/widgets/dynamic-core/ui-components/TooltipPopupCore';
import { CoreSavedDataPopup } from '../../core-ui-components/CoreSavedDataPopup';
import { htmlTagsRegex } from '../../core-ui-components/extra/CoreAddressAutoComplete';
import { useAppLogger } from 'Services/logger';
import { CoreSaveLeadNav } from '../../core-ui-components/CoreSaveLeadNav';

export interface EmilPaymentSystem {
  paymentMethod: string;
  isSystemPSP: boolean;
  accountFirstName?: string;
  accountLastName?: string;
  accountIban?: string;
  confirmationSEPA: boolean | undefined;
}

export interface EmilSystemProps {
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  productCode: string;
  stepData: StepInfoItem;
  recalculationTariff: RecalculationTariff;
  policyStepNames: string[];
}

const initialData: EmilPaymentSystem = {
  paymentMethod: PaymentMethodName.Sepa,
  isSystemPSP: true,
  confirmationSEPA: false,
};

export const validIbanFormat = new RegExp(
  /\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/,
);

export const EmilSystem: React.FC<EmilSystemProps> = (
  { lead, productData, productCode, stepData, recalculationTariff, policyStepNames },
) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const service = useWidgetService();
  const logger = useAppLogger();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const keyStep = paymentDataCoreKey;
  const { showAlert } = useAppAlertService();
  const [ isLoading, setLoading ] = React.useState<boolean>( false );
  const [ isValidEmail, setValidEmail ] = React.useState<boolean>( true );
  const isDisabledForm = false;
  const previousResultData = React.useRef<any>( {} );
  const isIgnoreOnRecalculation = React.useRef<boolean>( false );
  const [ messageError, setMessageError ] = React.useState<string>( '' );
  const [ isShowErrorPopup, setShowErrorPopup ] = React.useState<boolean>( false );
  const [ leadData, setLeadData ] = React.useState<ILeadData>( lead );
  const [ isRejected, setIsRejected ] = React.useState<boolean>( false );
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ itemErrorId, setItemErrorId ] = React.useState<string>( '' );
  const [ itemErrorIds, setItemErrorIds ] = React.useState<string[]>( lead[`${keyStep}_error_ids`] || [] );
  const { saveForLaterButton } = stepData || {};

  const currentStep = stepData && stepData.steps.find( ( subStep ) => subStep.name === paymentDataCoreKey );
  const savedPaymentData = lead.paymentData as unknown as EmilPaymentSystem;
  const paymentDataFields: EmilPaymentSystem = savedPaymentData !== null ? savedPaymentData : initialData;

  const formContext = useForm<EmilPaymentSystem>( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { errors, control, formState, getValues, trigger } = formContext;

  const [ paymentMethodName, setPaymentMethodName ] = React.useState<string>( paymentDataFields.paymentMethod );

  const { fieldsDefaultValues } = productData.configuration || {};
  const defaultLayoutData = productData.stepsConfig && productData.stepsConfig.layout
    ? productData.stepsConfig.layout[LayoutTypes.Default] : null;

  const onCloseErrorPopup = React.useCallback ( async ( ): Promise<void> => {
    if ( itemErrorId ) {
      const errorIds = [ ...itemErrorIds, itemErrorId ];
      setItemErrorIds( errorIds );

      await service.savedInfo( `${keyStep}_error_ids`, errorIds );
    }

    setShowErrorPopup( false );
    setMessageError( '' );
  }, [ itemErrorId, itemErrorIds, keyStep, service ] );

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    if ( !formState.isValid ) {
      trigger();
      return;
    }

    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions, formState.isValid, trigger ] );

  const rejectionPopup = React.useCallback(
    async ( isClickNextBtn: boolean = false, isSkipSaveForLaterValidation: boolean = false,
    ): Promise<void> => {
      actions.setValidForm( isEmpty( formState.errors ) );

      if ( stepData && stepData.steps ) {
        const leadStore: ILeadData = await service.getLead();
        const rules = generateRules( stepData.steps, currentStep?.name, isSkipSaveForLaterValidation );

        const { enabled, ruleCriteriaSFL } = stepData.saveForLaterButton || {};
        if ( enabled && ruleCriteriaSFL && isSkipSaveForLaterValidation ) {
          rules.unshift( ruleCriteriaSFL );
        }

        actions.setRejected( false );
        setIsRejected( false );

        if ( rules ) {
          rules.forEach( async ( item ) => {
            const rejectionData = evalFunction( leadStore || [], item );
            if ( rejectionData && rejectionData['rejectionMessage'] ) {
              setIsRejected( true );
              const rejectionMessage = rejectionData['rejectionMessage'];
              if ( !rejectionData['suppressPopup'] || isClickNextBtn ) {
                if ( !isClickNextBtn && item.itemId && itemErrorIds.includes( item.itemId ) ) {
                  actions.setRejected( true );
                  return;
                }

                setItemErrorId( item.itemId! );
                setMessageError( rejectionMessage );
                setShowErrorPopup( true );
              } else {
                const infoMess = rejectionMessage.replace( htmlTagsRegex, '' );
                logger.warn( infoMess );
              }
              actions.setRejected( true );

              return;
            } else {
              const cloneIds = itemErrorIds;

              if ( cloneIds && item.itemId && cloneIds.includes( item.itemId ) ) {
                const errorIds = [ ...cloneIds.filter( ( id ) => id !== item.itemId ) ];
                setItemErrorIds( errorIds );

                await service.savedInfo( `${keyStep}_error_ids`, errorIds );
              }
            }
          } );
        }
      }
    }, [ actions, currentStep, formState.errors, itemErrorIds, keyStep, logger, service, stepData ] );

  const infoPopup = React.useCallback( async ( fieldInfo: string ): Promise<void> => {
    if ( !fieldInfo ) {
      return;
    }

    if ( currentStep && currentStep.containers ) {
      const leadStore: ILeadData = await service.getLead();
      const infoMessage = getInfoMessage( fieldInfo, currentStep.containers, leadStore );

      if ( infoMessage ) {
        setMessageError( infoMessage );
        setShowErrorPopup( true );
      }

    }
  }, [ currentStep, service ] );

  const isDisabledField = React.useCallback ( ( item: ContainerFieldItem ): boolean => {
    if( isDisabledForm ) {
      return isDisabledForm;
    }

    if ( item.disableCriteria ) {
      return evalFunction( lead || [], item.disableCriteria ) as boolean;
    }

    return false;
  }, [ isDisabledForm, lead ] );

  const changePaymentType = React.useCallback( ( paymetType: PaymentMethodName ) => {
    setPaymentMethodName( paymetType );
  }, [] );

  const onSubmit = React.useCallback( async ( formData: EmilPaymentSystem ) => {
    rejectionPopup( true );

    if ( isRejected || actions.isRejected() ) {
      return;
    }

    if ( !leadData[keyStep] ) {
      return;
    }

    const accountIban = formData && formData.accountIban ? formData.accountIban.replace( /\s+/g, '' ) : '';
    const paymentData = {
      ...formData,
      isSystemPSP: initialData.isSystemPSP,
      accountIban,
    };

    const result = await service.savedInfo( paymentDataCoreKey, paymentData );

    if ( result.errorCode === 0 ) {
      if ( stepData.nextStep ) {
        actions.goToStep( stepData.nextStep, true );
      } else {
        try {
          const storeLeadData: ILeadData = await service.getLead();
          const initialLeadStatus = productData.stepsConfig?.initialLeadStatus;
          const leadStatus = initialLeadStatus ? initialLeadStatus : LeadStatus.Created;
          let defaultAccountEmail = '';

          if ( stepData && stepData.saveLeadOnComplete ) {
            defaultAccountEmail = stepData.saveLeadOnComplete.defaultAccountEmail;
          }

          let payloadLead = preparePayloadForLead(
            storeLeadData, productData, leadStatus, productCode, true, defaultAccountEmail,
          );

          if ( paymentData && paymentData['accountIban'] ) {
            payloadLead.bankAccount = {
              iban: paymentData['accountIban'],
            };
          }

          const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

          if ( savedLeadData ) {
            payloadLead.productVersionId = savedLeadData.policy.productVersionId;
          }

          const uploadedDocument = prepareUploadedDocument( productData.leadData.leadCode, leadData );

          if ( uploadedDocument ) {
            payloadLead.uploadedDocument = uploadedDocument;
          }

          const isClearLead = leadData.isLogin ? false : true;
          const res = savedLeadData ? await service.updateLead( payloadLead, isClearLead ) :
            await service.createLead( payloadLead, isClearLead );

          if ( res ) {
            const resLead = res.lead;
            actions.onSubmit( resLead.leadNumber );
          } else {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          }
        } catch( e ) {
          if ( e instanceof Error ) {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          } else {
            throw e;
          }
        }
      }
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, isRejected, keyStep, leadData, productCode,
    productData, rejectionPopup, service, showAlert, stepData, t ] );

  const isValidAccountIban = ( value: string ): string | boolean => {
    const iban = value.replace( /\s+/g, '' );
    const isValidFormat = validIbanFormat.test( iban );

    if( !isValidFormat ) {
      const errMessage = t( 'bookingFunnel.paymentData.accountIbanFormatError' );

      return errMessage;
    }

    if( !isValidIBAN( iban ) ) {
      const errMessage = t( 'bookingFunnel.ibanInvalid' );

      return errMessage;
    }

    return true;
  };

  React.useEffect( () => {
    for ( const errorItem of Object.values( errors ) ) {
      if ( errorItem && errorItem.ref ) {
        const field = document.getElementById( `${errorItem.ref.name}-label` );

        if ( field ) {
          field.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }

        break;
      }
    }
  }, [ errors ] );

  React.useEffect( () => {
    let isMounted = true;

    const formValues = formatFormData( getValues() );

    const saveDataToLocalStore = async ( ) => {
      if ( isEmpty( previousResultData.current ) ) {
        previousResultData.current = formValues;

        if ( !lead[keyStep] || isEmpty( lead[keyStep] ) ) {
          setLeadData( ( prevLead ) => ( {
            ...prevLead,
            [keyStep]: { ...prevLead[keyStep], ...previousResultData.current },
          } ) );

          await service.savedInfo( keyStep, previousResultData.current );
        }

        rejectionPopup();

        return;
      }
    };

    const saveFormValues = async ( fieldInfo: string ) => {
      try {
        await service.savedInfo( keyStep, previousResultData.current );
        const storeLeadData = await service.getLead( 0 );

        setLeadData( storeLeadData );

        rejectionPopup();
        infoPopup( fieldInfo );
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    saveDataToLocalStore();

    const changedField = getFieldDiff( formValues, previousResultData.current );

    if ( changedField && isMounted ) {
      previousResultData.current = formValues;

      const isSystemField = systemFields.some( ( item ) => changedField.startsWith( item ) );
      if ( isSystemField ) {
        isIgnoreOnRecalculation.current = true;
      } else {
        isIgnoreOnRecalculation.current = isIgnoreOnRecalculationFieldCore(
          changedField, currentStep!, productData.stepsConfig?.steps! );
      }

      const crossPolicyFields = generateCrossPolicyFields(
        productData.stepsConfig?.steps!, keyStep, changedField,
      );

      if ( actions.updatedStoreData && crossPolicyFields ) {
        actions.updatedStoreData( crossPolicyFields, previousResultData.current[changedField] );
      }

      saveFormValues( changedField );
    }

    return () => {
      isMounted = false;
    };
  }, [ formState.isValidating, service, showAlert, t, lead, getValues, formState.isValid,
    rejectionPopup, keyStep, currentStep, policyStepNames, infoPopup, productData.stepsConfig, actions ] );

  React.useEffect( () => {
    let isMounted = true;
    const emailVerification = async () => {
      try {
        const { visibleIfEmailValidated, visibleEmailSettings, previousStep } = stepData;
        if ( isMounted && visibleIfEmailValidated && !lead.isLogin ) {
          setLoading( true );

          const email = visibleEmailSettings && visibleEmailSettings.emailName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.emailName}` )[0] : undefined;
          const token = visibleEmailSettings && visibleEmailSettings.codeName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.codeName}` )[0] : undefined;

          if ( token && email ) {
            const payload: CompleteEmailVerification = {
              email,
              token,
            };
            const response = await service.completeEmailVerification( payload );

            if ( response && response.result ) {
              setValidEmail( true );
            } else {
              let previousStepName = previousStep!;
              if ( visibleEmailSettings && visibleEmailSettings.codeName ) {
                const splitToken = visibleEmailSettings.codeName.split( '.' );

                if ( splitToken.length >= 2 ) {
                  previousStepName = splitToken[0];
                }
              }

              actions.goToStep( previousStepName, false );
            }
          }
          setLoading( false );
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setLoading( false );
          }
        } else {
          throw e;
        }
      }
    };

    emailVerification();

    return () => {
      isMounted = false;
    };
  }, [ service, t, stepData, lead, actions ] );

  if ( lead[stepData.previousStep!] === null ) {
    actions.goToStep( stepData.previousStep!, false );
  }

  return (
    <FormProvider { ...formContext }>
      <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
        { isLoading && loadingOnFullContainer }
        <Fragment>
          { currentStep && (
            <Fragment>
              { currentStep.title && (
                <Row>
                  <Col className="p-0" md={ 12 }
                    dangerouslySetInnerHTML={ { __html: `${currentStep.title}` } }
                  >
                  </Col>
                </Row>
              ) }
              { currentStep.subTitle && (
                <Row>
                  <Col className="p-0" md={ 12 }
                    dangerouslySetInnerHTML={ { __html: `${currentStep.subTitle}` } }
                  >
                  </Col>
                </Row>
              ) }
              <div
                className={ `${defaultLayoutData && defaultLayoutData[stepData.id] ? 'layout-container' : ''}` }
                style={
                  renderGridStyles(
                    defaultLayoutData,
                    LayoutLevel.SubStep,
                    currentStep.id,
                  )
                }
              >
                { currentStep.containers.map( ( container, index ) => (
                  <Fragment key={ index }>
                    { evalFunction( leadData || [], container.visibleCriteria ) && (
                      <Row
                        id={ `${ container.groupName ? `${container.groupName}-${index}` : index }-box` }
                        className={ renderContainerClass( container, defaultLayoutData ) }
                        style={
                          renderGridStyles(
                            defaultLayoutData,
                            LayoutLevel.Container,
                            currentStep.id,
                            container,
                          )
                        }
                      >
                        { ( container.title || container.subTitle ) && (
                          <Col
                            md={ 12 }
                            className="p-0 title-box"
                            style={
                              renderGridStyles(
                                defaultLayoutData,
                                LayoutLevel.TitelContainer,
                                currentStep.id,
                                container,
                              )
                            }
                          >
                            { container.title && (
                              <div
                                className="container-title"
                                dangerouslySetInnerHTML={ { __html: `${container.title}` } }
                              >
                              </div>
                            ) }
                            { container.subTitle && (
                              <div
                                className="container-sub-title"
                                dangerouslySetInnerHTML={ { __html: `${container.subTitle}` } }
                              >
                              </div>
                            ) }
                          </Col>
                        ) }
                        { container.items && container.items.map( ( item, itemIdx ) => (
                          <Fragment key={ itemIdx }>
                            { evalFunction( leadData || [], item.visibleCriteria ) && (
                              <Fragment>
                                { item.type && item.type === FieldType.DownloadLink && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <DownloadLink
                                      downloadLinks={ item.downloadLinks }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.Label && (
                                  <CoreLabel
                                    leadData={ leadData }
                                    className={ renderContainerItemClassName( item ) }
                                    stepItem={ item }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  />
                                ) }
                                { item.type && item.type === FieldType.PremiumPreview && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreTariffInfo
                                      lead={ leadData }
                                      productData={ productData }
                                      productCode={ productCode }
                                      isIgnoreOnRecalculation={ isIgnoreOnRecalculation.current }
                                      recalculationTariff={ recalculationTariff }
                                      subSteps={ stepData.steps }
                                      keyStep={ keyStep }
                                      policyStepNames={ policyStepNames }
                                      settings={ item.settings as PremiumSettings }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.DownloadInfo && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreDocumentDownloadInfo
                                      isOpened={ false }
                                      iconCard={ documentIcon }
                                      productCode={ productCode }
                                      lead={ leadData }
                                      productData={ productData! }
                                      policyStepNames={ policyStepNames }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.DownloadInfoExtended && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreDocumentDownloadInfo
                                      isOpened={ false }
                                      iconCard={ documentIcon }
                                      productCode={ productCode }
                                      lead={ leadData }
                                      productData={ productData! }
                                      policyStepNames={ policyStepNames }
                                      settings={ item.settings as DocumentsSettings }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.Consent && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreCheckbox
                                      stepItem={ item }
                                      productCode={ productCode }
                                      productData={ productData }
                                      formData={ leadData[keyStep]! }
                                      isDisabled={ isDisabledField( item ) }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.FileUpload && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <FileUpload
                                      stepItem={ item }
                                      productData={ productData }
                                      stepKey={ keyStep }
                                      settings={ item.settings as FileUploadSettings }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.StateVariable && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreDynamicField
                                      lead={ leadData }
                                      stepItem={ item }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.MultiInsuredObject && (
                                  <div
                                    className={ renderContainerItemClassName( item ) }
                                    style={
                                      renderGridStyles(
                                        defaultLayoutData,
                                        LayoutLevel.Item,
                                        currentStep.id,
                                        container,
                                        item.id,
                                      )
                                    }
                                  >
                                    <CoreMultiInsuredObject
                                      productData={ productData }
                                      stepItem={ item }
                                      leadData={ leadData }
                                      formData={ leadData[keyStep]! }
                                      currentStepId={ currentStep.id }
                                      container={ container }
                                      nameStep={ keyStep }
                                    />
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.PaymentSettings && (
                                  <div className="bf-personal-core p-0 col-md-12">
                                    <div
                                      className={ renderContainerItemClassName( item ) }
                                      style={
                                        renderGridStyles(
                                          defaultLayoutData,
                                          LayoutLevel.Item,
                                          currentStep.id,
                                          container,
                                          item.id,
                                        )
                                      }
                                    >
                                      <Controller
                                        name="paymentMethod"
                                        control={ control }
                                        rules={ { required: true } }
                                        defaultValue={ paymentDataFields.paymentMethod }
                                        render={ ( props ) => (
                                          <FormGroup className="custom-btn-radio my-2">
                                            { item.settings && (
                                              <Fragment>
                                                { item.settings['hasSEPA'] && (
                                                  <div className="mb-4">
                                                    <FormControl
                                                      { ...props }
                                                      className="form-check-input"
                                                      type="radio"
                                                      id={ PaymentMethodName.Sepa }
                                                      value={ PaymentMethodName.Sepa }
                                                      checked={ props.value === PaymentMethodName.Sepa }
                                                      onClick={ () => changePaymentType( PaymentMethodName.Sepa ) }
                                                      onBlur={ props.onBlur }
                                                    />
                                                    <Form.Label
                                                      className="btn btn-border-radio"
                                                      htmlFor={ PaymentMethodName.Sepa }
                                                    >
                                                      { t( 'bookingFunnel.paymentData.sepaMandate' ) }
                                                      <img className="mr-3" src={ sepaIconUrl }
                                                        alt={ PaymentMethodName.Sepa }
                                                      />
                                                    </Form.Label>
                                                  </div>
                                                ) }
                                                { item.settings['hasInvoice'] && (
                                                  <div className="mb-4">
                                                    <FormControl
                                                      { ...props }
                                                      className="form-check-input"
                                                      type="radio"
                                                      id={ PaymentMethodName.Invoice }
                                                      value={ PaymentMethodName.Invoice }
                                                      checked={ props.value === PaymentMethodName.Invoice }
                                                      onClick={ () => changePaymentType( PaymentMethodName.Invoice ) }
                                                      onBlur={ props.onBlur }
                                                    />
                                                    <Form.Label
                                                      className="btn btn-border-radio"
                                                      htmlFor={ PaymentMethodName.Invoice }
                                                    >
                                                      { t( 'bookingFunnel.paymentData.invoice' ) }
                                                      <img className="mr-3" src={ billLogo }
                                                        alt={ PaymentMethodName.Invoice }
                                                      />
                                                    </Form.Label>
                                                  </div>
                                                ) }
                                              </Fragment>
                                            ) }
                                            <Form.Control
                                              type="hidden"
                                              isInvalid={ errors.paymentMethod !== undefined }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                              { t( 'base:forms.messages.fieldRequired',
                                                { fieldLabel: t( 'bookingFunnel.paymentData.paymentMethod' ) } ) }
                                            </Form.Control.Feedback>
                                          </FormGroup>
                                        ) }
                                      />
                                    </div>
                                    { paymentMethodName === PaymentMethodName.Sepa && (
                                      <Fragment>
                                        <div className={ renderContainerItemClassName( item ) }>
                                          <Controller
                                            name="accountFirstName"
                                            control={ control }
                                            rules={ { required: true } }
                                            defaultValue={ paymentDataFields.accountFirstName || '' }
                                            render={ ( props ) => (
                                              <FormGroup controlId={ props.name }>
                                                <Form.Label>
                                                  { t( 'bookingFunnel.personalData.firstName' ) }
                                                </Form.Label>
                                                <Form.Control
                                                  { ...props }
                                                  placeholder={ t( 'bookingFunnel.personalData.firstName' ) }
                                                  type="text"
                                                  isInvalid={ errors[props.name] !== undefined }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  { t( 'base:forms.messages.fieldRequired',
                                                    { fieldLabel: t( 'bookingFunnel.personalData.firstName' ) } ) }
                                                </Form.Control.Feedback>
                                              </FormGroup>
                                            ) }
                                          />
                                        </div>
                                        <div className={ renderContainerItemClassName( item ) }>
                                          <Controller
                                            name="accountLastName"
                                            control={ control }
                                            rules={ { required: true } }
                                            defaultValue={ paymentDataFields.accountLastName || '' }
                                            render={ ( props ) => (
                                              <FormGroup controlId={ props.name }>
                                                <Form.Label>
                                                  { t( 'bookingFunnel.personalData.lastName' ) }
                                                </Form.Label>
                                                <Form.Control
                                                  { ...props }
                                                  placeholder={ t( 'bookingFunnel.personalData.lastName' ) }
                                                  type="text"
                                                  isInvalid={ errors[props.name] !== undefined }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  { t( 'base:forms.messages.fieldRequired',
                                                    { fieldLabel: t( 'bookingFunnel.personalData.lastName' ) } ) }
                                                </Form.Control.Feedback>
                                              </FormGroup>
                                            ) }
                                          />
                                        </div>
                                        <div className={ renderContainerItemClassName( item ) }>
                                          <Controller
                                            name="accountIban"
                                            control={ control }
                                            rules={ {
                                              required: true,
                                              validate: ( value ) => {
                                                return isValidAccountIban( value );
                                              },
                                            } }
                                            defaultValue={ paymentDataFields.accountIban || '' }
                                            render={ ( props ) => (
                                              <FormGroup controlId={ props.name }>
                                                <Form.Label id={ `${props.name}-label` }>
                                                  { t( 'bookingFunnel.paymentData.accountIban' ) }
                                                </Form.Label>
                                                <Form.Control
                                                  { ...props }
                                                  type="text"
                                                  placeholder={ t( 'bookingFunnel.paymentData.accountIban' ) }
                                                  isInvalid={ errors[props.name] !== undefined }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  { errors[props.name]?.message ? ( errors[props.name]?.message
                                                  ) : (
                                                    <Fragment>
                                                      { t( 'base:forms.messages.fieldRequired',
                                                        { fieldLabel: t( 'bookingFunnel.paymentData.accountName' ) } ) }
                                                    </Fragment>
                                                  ) }
                                                </Form.Control.Feedback>
                                              </FormGroup>
                                            ) }
                                          />
                                        </div>
                                      </Fragment>
                                    ) }
                                  </div>
                                ) }
                                { item.type && item.type === FieldType.Policy && productData.variables.filter(
                                  ( v ) =>
                                    `${v.name}_${v.groupName}` === `${item.fieldName}_${item.insuredObjectName}`,
                                )
                                  .map( ( variable, idx ) => (
                                    <div
                                      key={ idx }
                                      className={ renderContainerItemClassName( item ) }
                                      style={
                                        renderGridStyles(
                                          defaultLayoutData,
                                          LayoutLevel.Item,
                                          currentStep.id,
                                          container,
                                          item.id,
                                        )
                                      }
                                    >
                                      <CoreFieldPanel
                                        key={ idx }
                                        formData={ leadData[keyStep]! }
                                        factors={ productData.factors }
                                        variable={ variable }
                                        stepItem={ item }
                                        fieldDefaultValue={
                                          fieldsDefaultValues && fieldsDefaultValues.find(
                                            ( f ) =>
                                              f.insuredObjectName.toLowerCase() === variable.groupName?.toLowerCase() &&
                                            f.productFieldName.toLowerCase() === variable.name.toLowerCase() )
                                        }
                                        isDisabled={ isDisabledField( item ) }
                                      />
                                    </div>
                                  ) ) }
                              </Fragment>
                            ) }
                          </Fragment>
                        ) ) }
                      </Row>
                    ) }
                  </Fragment>
                ) ) }
              </div>
            </Fragment>
          ) }
        </Fragment>
        <Row className="justify-content-center align-items-center mt-0 mx-0">
          <CoreCirclesNavigationMobile lead={ lead } />
        </Row>
        <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4 mx-0">
          { saveForLaterButton && saveForLaterButton.enabled && (
            <>
              { saveForLaterButton.suppressEmailDialog ? (
                <CoreSaveLeadNav
                  currentStep={ currentStep! }
                  stepData={ stepData }
                  productCode={ productCode }
                  productData={ productData! }
                  disabled={ formState.isSubmitting }
                  rejectionPopup={ rejectionPopup }
                  isRejected={ isRejected }
                />
              ) : (
                <Button
                  id={ `${stepData.nextStep ? stepData.nextStep+'_' : ''}save_for_later` }
                  type="button"
                  variant="primary"
                  disabled={ formState.isSubmitting }
                  onClick={ () => onSaved( true ) }
                  className="col-sm-12 mr-0"
                >
                  { saveForLaterButton.buttonText ?
                    saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
                </Button>
              ) }
            </>
          ) }
          <Button
            id={ `${COREBFStep.PaymentStep}_next` }
            type="submit"
            variant="primary"
            disabled={ formState.isSubmitting || !isValidEmail || !paymentMethodName }
            className="col-sm-12 mr-0"
          >
            { stepData.nextStep ? t( 'bookingFunnel.nextBtn' ) : t( 'bookingFunnel.applyNowBtn' ) }
          </Button>
          <Button
            id={ `${COREBFStep.PaymentStep}_back` }
            type="button"
            variant="link"
            className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
            onClick={ () => actions.goToStep( stepData.previousStep!, true ) }
          >
            { t( 'bookingFunnel.previousBtn' ) }
          </Button>
        </Row>
      </Form>
      { isShowErrorPopup && messageError && (
        <TooltipPopupCore
          tooltipInfo={ messageError }
          onClose={ () => onCloseErrorPopup( ) }
        />
      ) }
      { isShowSavedStep && (
        <CoreSavedDataPopup
          currentStep={ stepData.nameStep }
          productCode={ productCode }
          productData={ productData! }
          onClose={ () => onCloseSavedPopup( ) }
          goNextStep={ goNextStep }
          policyStepNames={ policyStepNames }
        />
      ) }
    </FormProvider>
  );
};
