import React from 'react';
import { Col, Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PaymentCoreStepItem } from '../../../PaymentCoreStep';
import { AdyenCheckout, CoreConfiguration,
  ICore, SepaConfiguration, SepaDirectDebit,
  UIElement } from '@adyen/adyen-web';
import { useAppLogger } from 'Services/logger';
import { Controller, useFormContext } from 'react-hook-form';

export interface IbanFieldsProps {
  paymentFields: PaymentCoreStepItem;
  adyenConfiguration: CoreConfiguration;
  isSetupPayment: boolean;
  onInit: ( component: UIElement ) => void;
  onLoaded: ( ) => void;
};

export const IbanFields: React.FC<IbanFieldsProps> = ( { paymentFields, adyenConfiguration, onInit, onLoaded } ) => {
  const logger = useAppLogger();
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const { errors, control } = useFormContext();
  const isFirstLoad = React.useRef<boolean>( true );

  React.useEffect( () => {
    const initializeAdyenCheckout = async () => {
      try {
        if ( isFirstLoad.current ) {
          const checkout: ICore = await AdyenCheckout( adyenConfiguration );
          const sepaConfig = { showPayButton: false, holderName: false } as SepaConfiguration;
          const component = ( new SepaDirectDebit( checkout, sepaConfig ) );
          component.mount( '#sepadirectdebit-container' );
          onInit( component );
          onLoaded();
          isFirstLoad.current = false;
        }
      } catch ( error ) {
        logger.error( 'Error initializing Adyen Checkout:', error );
      }
    };

    initializeAdyenCheckout();
  }, [ adyenConfiguration, logger, onInit, onLoaded ] );

  return (
    <>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountFirstName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountFirstName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.firstName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountFirstName !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.firstName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountLastName"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountLastName || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.lastName' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="text"
                isInvalid={ errors.accountLastName !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.lastName' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } className="mb-4">
        <Controller
          name="accountEmail"
          control={ control }
          rules={ { required: true } }
          defaultValue={ paymentFields.accountEmail || '' }
          render={ ( props ) => (
            <FormGroup controlId={ props.name }>
              <Form.Label>
                { t( 'bookingFunnel.personalData.email' ) }
              </Form.Label>
              <Form.Control
                { ...props }
                type="email"
                isInvalid={ errors.accountEmail !== undefined }
              />
              <Form.Control.Feedback type="invalid">
                { t( 'base:forms.messages.fieldRequired',
                  { fieldLabel: t( 'bookingFunnel.personalData.email' ) } ) }
              </Form.Control.Feedback>
            </FormGroup>
          ) }
        />
      </Col>
      <Col md={ 12 } id="sepadirectdebit-container" className="mt-4"></Col>
    </>
  );
};
