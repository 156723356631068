import React from 'react';
import { IBookingFunnelStepProps } from 'App/components/widgets/booking-funnel/BookingFunnel';
import { CoreAdyenForm } from './forms/adyen/CoreAdyenForm';
import { UseFormMethods } from 'react-hook-form';
import { PaymentCoreStepItem } from '../PaymentCoreStep';
import { RecalculationTariff } from '../../../DynamicCore';
import { StepInfoItem } from '../../../interfaces';

export interface AdyenSystemCoreProps extends IBookingFunnelStepProps {
  productCode: string;
  formContext: UseFormMethods<PaymentCoreStepItem>;
  stepData: StepInfoItem;
  policyStepNames: string[];
  recalculationTariff: RecalculationTariff;
}

export const AdyenSystem: React.FC<AdyenSystemCoreProps> = (
  { lead, productData, productCode, formContext, stepData, policyStepNames, recalculationTariff },
) => {
  return (
    <CoreAdyenForm
      formContext={ formContext }
      lead={ lead }
      productData={ productData }
      productCode={ productCode }
      stepData={ stepData }
      policyStepNames={ policyStepNames }
      recalculationTariff={ recalculationTariff }
    />
  );
};
