import React, { Fragment } from 'react';
import jsonpath from 'jsonpath';
import { useTranslation } from 'react-i18next';
import { IDataFactorsAndVariables } from '../../../booking-funnel/BookingFunnel';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { AppAlertUI, useAppAlertService } from 'App/components/utils/alerts/AppAlertService';
import {
  CompleteEmailVerification,
  ILeadData,
  ITariffDataStep,
  StepItem,
} from 'Services/widgets/interfaces';
import { LoadingSpinner, useLoadingSpinnerOnFullContainer } from 'App/components/utils/LoadingSpinner';
import {
  useCoreActions,
  RecalculationTariff,
} from '../../DynamicCore';
import { IScreenType } from 'Services/widgets/interfaces';
import { useWidgetService } from 'Services/widget';
import { isEmpty } from 'lodash';
import { StepInfoItem } from '../../interfaces';
import {
  preparePayloadForLead,
  generatePolicyObject,
  generateMultiObjects,
  keyMultiInsuredObjects,
  generateSavedData,
  generateSteps,
  limitRequest,
  formatFormData,
  systemFields,
  isIgnoreOnRecalculationFieldCore,
  generateCrossPolicyFields,
  generateRules,
} from '../../core-hooks';
import { LeadStatus } from '@cover42/ts-contracts';
import { CoreCirclesNavigationMobile } from '../core-ui-components/CoreCirclesNavigationMobile';
import { CoreSavedDataPopup } from '../core-ui-components/CoreSavedDataPopup';
import { useAppLogger } from 'Services/logger';
import { JSONComponentType } from 'App/components/widgets/booking-funnel/enum';
import { CoreRenderContainer } from '../core-ui-components/CoreRenderContainer';
import { getFieldDiff } from 'App/components/widgets/booking-funnel/booking-funnel-hooks';
import { evalFunction } from 'App/components/widgets/booking-funnel/functions';
import { TooltipPopupCore } from '../../ui-components/TooltipPopupCore';
import { htmlTagsRegex } from '../core-ui-components/extra/CoreAddressAutoComplete';
import { CoreSaveLeadNav } from '../core-ui-components/CoreSaveLeadNav';
import { CoreLeadNav } from '../core-ui-components/summary-views/CoreLeadNav';

export interface SummaryCoreStepProps {
  productCode: string;
  lead: ILeadData;
  productData: IDataFactorsAndVariables;
  screenType: IScreenType;
  recalculationTariff: RecalculationTariff;
  stepData: StepInfoItem;
  policyStepNames: string[];
  isDisabledForm: boolean;
  listLeadStatuses?: string[];
}

export const SummaryCoreStep: React.FC<SummaryCoreStepProps> = (
  { productCode, lead, productData, recalculationTariff, stepData, policyStepNames, isDisabledForm, listLeadStatuses },
) => {
  const { t } = useTranslation( [ 'widgets' ] );
  const { showAlert } = useAppAlertService();
  const service = useWidgetService();
  const actions = useCoreActions();
  const logger = useAppLogger();
  const loadingOnFullContainer = useLoadingSpinnerOnFullContainer();
  const previousFormData = React.useRef<ITariffDataStep>( {} );
  const previousLimitRequest = React.useRef<number>( 0 );
  const isIgnoreOnRecalculation = React.useRef<boolean>( false );
  const keySummaryData = stepData.nameStep;

  const [ isLoading, setLoading ] = React.useState<boolean>( false );
  const [ isValidEmail, setValidEmail ] = React.useState<boolean>( true );
  const [ isShowSavedStep, setShowSavedStep ] = React.useState<boolean>( false );
  const [ messageError, setMessageError ] = React.useState<string>( '' );
  const [ isShowErrorPopup, setShowErrorPopup ] = React.useState<boolean>( false );
  const [ isRejected, setIsRejected ] = React.useState<boolean>( false );
  const [ leadState, setLeadState ] = React.useState<ILeadData>( lead );
  const [ itemErrorId, setItemErrorId ] = React.useState<string>( '' );
  const [ itemErrorIds, setItemErrorIds ] = React.useState<string[]>( lead[`${keySummaryData}_error_ids`] || [] );
  const [ isSubmitting, setIsSubmitting ] = React.useState<boolean>( false );

  const recalculationConfig: RecalculationTariff = {
    ...recalculationTariff,
    isCheckedUnderwriting: true,
  };

  const summaryStep = stepData.steps && stepData.steps.find( ( subStep ) => subStep.name === keySummaryData );

  let submitButtonMessage = t( 'bookingFunnel.applyNowBtn' );
  if ( productData && productData.stepsConfig && productData.stepsConfig['finishButtonText'] ) {
    submitButtonMessage = productData.stepsConfig['finishButtonText'];
  }
  const { saveForLaterButton } = stepData || {};

  const formContext = useForm( {
    mode: 'onChange',
    shouldUnregister: true,
  } );

  const { formState, getValues } = formContext;

  const onCloseSavedPopup = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const goNextStep = React.useCallback ( async ( ): Promise<void> => {
    setShowSavedStep( false );
  }, [] );

  const onCloseErrorPopup = React.useCallback ( async ( ): Promise<void> => {
    if ( itemErrorId ) {
      const errorIds = [ ...itemErrorIds, itemErrorId ];
      setItemErrorIds( errorIds );

      await service.savedInfo( `${keySummaryData}_error_ids`, errorIds );
    }

    setShowErrorPopup( false );
    setMessageError( '' );
  }, [ itemErrorId, itemErrorIds, keySummaryData, service ] );

  const rejectionPopup = React.useCallback(
    async ( isClickNextBtn: boolean = false, isSkipSaveForLaterValidation: boolean = false,
    ): Promise<void> => {
      if ( isDisabledForm ) {
        return;
      }

      actions.setValidForm( isEmpty( formState.errors ) );

      if ( stepData && stepData.steps ) {
        const leadStore: ILeadData = await service.getLead();
        const rules = generateRules( stepData.steps, summaryStep?.name, isSkipSaveForLaterValidation );

        const { enabled, ruleCriteriaSFL } = stepData.saveForLaterButton || {};
        if ( enabled && ruleCriteriaSFL && isSkipSaveForLaterValidation ) {
          rules.unshift( ruleCriteriaSFL );
        }

        actions.setRejected( false );
        setIsRejected( false );

        if ( rules ) {
          rules.forEach( async ( item ) => {
            const rejectionData = evalFunction( leadStore || [], item );
            if ( rejectionData && rejectionData['rejectionMessage'] ) {
              setIsRejected( true );
              const rejectionMessage = rejectionData['rejectionMessage'];
              if ( !rejectionData['suppressPopup'] || isClickNextBtn ) {
                if ( !isClickNextBtn && item.itemId && itemErrorIds.includes( item.itemId ) ) {
                  actions.setRejected( true );
                  return;
                }

                setItemErrorId( item.itemId! );
                setMessageError( rejectionMessage );
                setShowErrorPopup( true );
              } else {
                const infoMess = rejectionMessage.replace( htmlTagsRegex, '' );
                logger.warn( infoMess );
              }
              actions.setRejected( true );

              return;
            } else {
              const cloneIds = itemErrorIds;

              if ( cloneIds && item.itemId && cloneIds.includes( item.itemId ) ) {
                const errorIds = [ ...cloneIds.filter( ( id ) => id !== item.itemId ) ];
                setItemErrorIds( errorIds );

                await service.savedInfo( `${keySummaryData}_error_ids`, errorIds );
              }
            }
          } );
        }
      }
    }, [ actions, formState.errors, isDisabledForm, itemErrorIds,
      keySummaryData, logger, service, stepData, summaryStep ] );

  const onSaved = React.useCallback ( ( isShow: boolean ): void => {
    actions.reloadLead();
    setShowSavedStep( isShow );
  }, [ actions ] );

  const onSavedApplication = React.useCallback ( async ( status: string ): Promise<void> => {
    if ( !isEmpty( formState.errors ) || !formState.isValid ) {
      return;
    }

    rejectionPopup( true );

    if ( isRejected || actions.isRejected() ) {
      return;
    }

    setIsSubmitting( true );
    const formData: ITariffDataStep = getValues();
    const savedResult = await service.savedInfo( keySummaryData, formData );
    if ( savedResult.errorCode === 0 ) {
      try {
        const leadData: ILeadData = await service.getLead();
        const initialLeadStatus = productData.stepsConfig?.initialLeadStatus;
        let leadStatus = initialLeadStatus ? initialLeadStatus : LeadStatus.Created;

        if ( status ) {
          leadStatus = status;
        }

        let payloadLead = preparePayloadForLead(
          leadData, productData, leadStatus, productCode, true,
        );

        const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

        if ( savedLeadData ) {
          payloadLead.productVersionId = savedLeadData.policy.productVersionId;
        }

        const result = savedLeadData ? await service.updateLead( payloadLead, true ) :
          await service.createLead( payloadLead, true );

        if ( result ) {
          const resLead = result.lead;
          actions.onSubmit( resLead.leadNumber );
        } else {
          showAlert( {
            message: t( 'base:forms.messages.errorSave' ),
            type: 'danger',
          } );
        }
        setIsSubmitting( false );
      } catch( e ) {
        setIsSubmitting( false );
        if ( e instanceof Error ) {
          showAlert( {
            message: t( 'base:forms.messages.errorSave' ),
            type: 'danger',
          } );
        } else {
          throw e;
        }
      }
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, formState.errors, formState.isValid, getValues, isRejected, keySummaryData,
    productCode, productData, rejectionPopup, service, showAlert, t ] );

  const onSubmit = React.useCallback( async ( formData: ITariffDataStep ) => {
    rejectionPopup( true );

    if ( isRejected || actions.isRejected() ) {
      return;
    }

    if ( !leadState[keySummaryData] ) {
      return;
    }

    const savedResult = await service.savedInfo( keySummaryData, formData );
    if ( savedResult.errorCode === 0 ) {
      if ( stepData.nextStep ) {
        actions.goToStep( stepData.nextStep, true );
      } else {
        try {
          const leadData: ILeadData = await service.getLead();
          const initialLeadStatus = productData.stepsConfig?.initialLeadStatus;
          const leadStatus = initialLeadStatus ? initialLeadStatus : LeadStatus.Created;
          let defaultAccountEmail = '';

          if ( stepData && stepData.saveLeadOnComplete ) {
            defaultAccountEmail = stepData.saveLeadOnComplete.defaultAccountEmail;
          }

          let payloadLead = preparePayloadForLead(
            leadData, productData, leadStatus, productCode, true, defaultAccountEmail,
          );

          const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

          if ( savedLeadData ) {
            payloadLead.productVersionId = savedLeadData.policy.productVersionId;
          }

          const isClearLead = lead.isLogin ? false : true;
          const result = savedLeadData ? await service.updateLead( payloadLead, isClearLead ) :
            await service.createLead( payloadLead, isClearLead );

          if ( result ) {
            const resLead = result.lead;
            actions.onSubmit( resLead.leadNumber );
          } else {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          }
        } catch( e ) {
          if ( e instanceof Error ) {
            showAlert( {
              message: t( 'base:forms.messages.errorSave' ),
              type: 'danger',
            } );
          } else {
            throw e;
          }
        }
      }
    } else {
      showAlert( {
        message: t( 'base:forms.messages.errorSave' ),
        type: 'danger',
      } );
    }
  }, [ actions, isRejected, keySummaryData, lead.isLogin, leadState, productCode,
    productData, rejectionPopup, service, showAlert, stepData, t ] );

  React.useEffect( () => {
    let isMounted = true;

    const formValues = formatFormData( getValues() );

    const saveDataToLocalStore = async ( ) => {
      if ( isEmpty( previousFormData.current ) ) {
        previousFormData.current = formValues;

        if ( !lead[keySummaryData] || isEmpty( lead[keySummaryData] ) ) {
          setLeadState( ( prevLead ) => ( {
            ...prevLead,
            [keySummaryData]: { ...prevLead[keySummaryData], ...previousFormData.current },
          } ) );

          await service.savedInfo( keySummaryData, previousFormData.current );
        }

        rejectionPopup();

        return;
      }
    };

    const saveFormValues = async ( fieldInfo: string ) => {
      try {
        await service.savedInfo( keySummaryData, previousFormData.current );
        const storeLeadData = await service.getLead( 0 );

        setLeadState( storeLeadData );

        rejectionPopup();
      } catch( e ) {
        showAlert( {
          message: t( 'base:forms.messages.errorSave' ),
          type: 'danger',
        } );
      }
    };

    saveDataToLocalStore();

    const changedField = getFieldDiff( formValues, previousFormData.current );

    if ( changedField && isMounted ) {
      previousFormData.current = formValues;

      const isSystemField = systemFields.some( ( item ) => changedField.startsWith( item ) );
      if ( isSystemField ) {
        isIgnoreOnRecalculation.current = true;
      } else {
        isIgnoreOnRecalculation.current = isIgnoreOnRecalculationFieldCore(
          changedField, summaryStep!, productData.stepsConfig?.steps! );
      }

      const crossPolicyFields = generateCrossPolicyFields(
        productData.stepsConfig?.steps!, keySummaryData, changedField,
      );

      if ( actions.updatedStoreData && crossPolicyFields ) {
        actions.updatedStoreData( crossPolicyFields, previousFormData.current[changedField] );
      }

      saveFormValues( changedField );
    }

    return () => {
      isMounted = false;
    };
  }, [ actions, formState.isValid, formState.isValidating, getValues, keySummaryData,
    lead, productData.stepsConfig, rejectionPopup, service, showAlert, summaryStep, t ] );

  React.useEffect( () => {
    let isMounted = true;
    const emailVerification = async () => {
      try {
        const { visibleIfEmailValidated, visibleEmailSettings, previousStep } = stepData;
        if ( isMounted && visibleIfEmailValidated && !lead.isLogin ) {
          setLoading( true );

          const email = visibleEmailSettings && visibleEmailSettings.emailName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.emailName}` )[0] : undefined;
          const token = visibleEmailSettings && visibleEmailSettings.codeName ?
            jsonpath.query( lead, `$.${visibleEmailSettings.codeName}` )[0] : undefined;

          if ( token && email ) {
            const payload: CompleteEmailVerification = {
              email,
              token,
            };
            const response = await service.completeEmailVerification( payload );

            if ( response && response.result ) {
              setValidEmail( true );
            } else {
              let previousStepName = previousStep!;
              if ( visibleEmailSettings && visibleEmailSettings.codeName ) {
                const splitToken = visibleEmailSettings.codeName.split( '.' );

                if ( splitToken.length >= 2 ) {
                  previousStepName = splitToken[0];
                }
              }

              actions.goToStep( previousStepName, false );
            }
          }
          setLoading( false );
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          if ( isMounted ) {
            setLoading( false );
          }
        } else {
          throw e;
        }
      }
    };

    emailVerification();

    return () => {
      isMounted = false;
    };
  }, [ service, t, stepData, lead, actions ] );

  React.useEffect( () => {
    let isMounted = true;
    const updateLeadData = async () => {
      try {
        const { saveLeadOnComplete } = stepData;

        if ( isMounted && saveLeadOnComplete && saveLeadOnComplete.enabled && saveLeadOnComplete.endStatus ) {
          const savedLeadData = await service.ckeckLeadData( productData.leadData.leadCode );

          if ( savedLeadData ) {
            const { status, policy } = savedLeadData;
            const { stepsConfig, insuredObjects } = productData;

            if ( saveLeadOnComplete.endStatus === status && policy && policy.policyObjects && stepsConfig ) {
              const policyObject = generatePolicyObject( policy.policyObjects, insuredObjects );

              if ( !isEmpty( policyObject ) ) {
                const multiInsuredObjects = generateMultiObjects( policy.policyObjects, insuredObjects );
                await service.savedInfo( keyMultiInsuredObjects, multiInsuredObjects );

                let allSteps = stepsConfig.steps;
                const steps = generateSteps( allSteps as StepItem[] );

                const policySteps = steps.filter( ( p ) => p.componentType === JSONComponentType.Policy );

                if ( policySteps && policySteps.length ) {
                  policySteps.forEach( async ( item ) => {
                    const policyStepData = generateSavedData( policyObject, item.steps, multiInsuredObjects );

                    await service.savedInfo( item.nameStep, policyStepData );
                  } );
                }
              }
            } else {
              setTimeout( () => {
                if ( previousLimitRequest.current < limitRequest ) {
                  previousLimitRequest.current = previousLimitRequest.current + 1;

                  updateLeadData();
                }
              }, 2000 );
            }
          }
        }
      } catch( e ) {
        if ( e instanceof Error ) {
          logger.error( e );
        } else {
          throw e;
        }
      }
    };

    updateLeadData();

    return () => {
      isMounted = false;
    };
  }, [ service, stepData, actions, productData.leadData.leadCode,
    productData.stepsConfig, productData.insuredObjects, logger, productData ] );

  if ( lead[stepData.previousStep!] === null ) {
    actions.goToStep( stepData.previousStep!, false );
  }

  if ( productData === null ) {
    return <div className="bf-loading col-auto py-4"><LoadingSpinner /></div>;
  }

  return (
    <FormProvider { ...formContext }>
      <div id="summary-step">
        <Row className="mt-3 mx-0">
          <Col md={ 12 } id="alert-summary-page">
            <AppAlertUI />
          </Col>
          <Col md={ 12 } className="px-0">
            <Form noValidate onSubmit={ formContext.handleSubmit( onSubmit ) }>
              <Fragment>
                { isLoading && loadingOnFullContainer }
                { summaryStep && (
                  <Fragment>
                    { stepData.title && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${stepData.title}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { stepData.subTitle && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${stepData.subTitle}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { summaryStep.title && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${summaryStep.title}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    { summaryStep.subTitle && (
                      <Row>
                        <Col className="p-0" md={ 12 }
                          dangerouslySetInnerHTML={ { __html: `${summaryStep.subTitle}` } }
                        >
                        </Col>
                      </Row>
                    ) }
                    <CoreRenderContainer
                      storeLeadData={ lead }
                      leadData={ leadState }
                      productData={ productData }
                      productCode={ productCode }
                      stepData={ stepData }
                      currentStep={ summaryStep }
                      recalculationTariff={ recalculationConfig }
                      containerName={ '' }
                      policyStepNames={ policyStepNames }
                      isIgnoreOnRecalculation={ true }
                      isDisabledForm={ false }
                      onSaved={ onSaved }
                    />
                  </Fragment>
                ) }
              </Fragment>
              <Row className="justify-content-center align-items-center mt-0 mx-0">
                <CoreCirclesNavigationMobile lead={ lead } />
              </Row>
              <Row className="bf-footer-btn-panel justify-content-center align-items-center mt-4 mx-0">
                { !isDisabledForm && !listLeadStatuses && saveForLaterButton && saveForLaterButton.enabled && (
                  <>
                    { saveForLaterButton.suppressEmailDialog ? (
                      <CoreSaveLeadNav
                        currentStep={ summaryStep! }
                        stepData={ stepData }
                        productCode={ productCode }
                        productData={ productData! }
                        disabled={ formState.isSubmitting || !isValidEmail }
                        rejectionPopup={ rejectionPopup }
                        isRejected={ isRejected }
                      />
                    ) : (
                      <Button
                        id={ `${stepData.nextStep ? stepData.nextStep+'_' : ''}save_for_later` }
                        type="button"
                        variant="primary"
                        disabled={ formState.isSubmitting || !isValidEmail }
                        onClick={ () => onSaved( true ) }
                        className="col-sm-12 mr-0"
                      >
                        { saveForLaterButton.buttonText ?
                          saveForLaterButton.buttonText : t( 'bookingFunnel.saveForLaterBtn' ) }
                      </Button>
                    ) }
                  </>
                ) }
                { !isDisabledForm && listLeadStatuses && (
                  <CoreLeadNav
                    leadStatuses={ listLeadStatuses }
                    currentLeadStatus={ LeadStatus.Created }
                    isSubmitting={ isSubmitting }
                    onSaveLeadSubmit={ onSavedApplication }
                  />
                ) }
                { !isDisabledForm && !listLeadStatuses && (
                  <Button
                    id={ `${stepData.nextStep ? stepData.nextStep+'_next' : 'create_lead'}` }
                    type="submit"
                    variant="primary"
                    disabled={ formState.isSubmitting || !isValidEmail }
                    className="col-sm-12 mr-0"
                  >
                    { stepData.nextStep ? t( 'bookingFunnel.nextBtn' ) : submitButtonMessage }
                  </Button>
                ) }
                { stepData.previousStep && (
                  <Button
                    id={ `${stepData.previousStep}_back` }
                    type="button"
                    variant="link"
                    className="mt-2 mb-2 mx-0 py-0 text-c-black custom-back-button"
                    onClick={ () => actions.goToStep( stepData.previousStep!, true ) }
                  >
                    { t( 'bookingFunnel.previousBtn' ) }
                  </Button>
                ) }
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      { isShowErrorPopup && messageError && (
        <TooltipPopupCore
          tooltipInfo={ messageError }
          onClose={ () => onCloseErrorPopup( ) }
        />
      ) }
      { isShowSavedStep && (
        <CoreSavedDataPopup
          currentStep={ stepData.nameStep }
          productCode={ productCode }
          productData={ productData! }
          onClose={ () => onCloseSavedPopup( ) }
          goNextStep={ goNextStep }
          policyStepNames={ policyStepNames }
        />
      ) }
    </FormProvider>
  );
};
