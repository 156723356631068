import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { Form, FormGroup, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { apiDateTimeFormat } from '../../../../../ui-utils';
import { formatDatePolicy, renderFieldLabel } from '../../core-hooks';
import { DatePickerComponent } from './DatePickerComponent';
import { blankLink } from 'config';
import {
  checkedRecalculation,
  checkedUnderwriting,
  isRequiredField,
} from '../../../booking-funnel/booking-funnel-hooks';
import { useCoreActions } from '../../DynamicCore';

export interface PolicyStartDateFieldProps {
  valueField: string;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: Boolean;
  stepItem?: ContainerFieldItem;
  isDisabled?: boolean;
}

export const PolicyStartDateField: React.FC<PolicyStartDateFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  stepItem,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const dayPickerRef = React.useRef<HTMLDivElement>( null );
  const popperRef = React.useRef<HTMLDivElement>( null );
  const todayDate = dayjs().format( apiDateTimeFormat );;
  const toDate = dayjs().add( 1, 'year' ).format();
  const fieldName = isShortName ?
    variable.name : `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
  const dateField = valueField ? valueField : todayDate;

  const [ isOpenDatePicker, setOpenDatePicker ] = React.useState<boolean>( false );

  const { errors, setValue } = useFormContext();

  const fullDate = dayjs( dateField, formatDatePolicy );

  const [ dayDate, setDayDate ] = React.useState<string>( fullDate.format( 'DD' ) );
  const [ monthDate, setMonthDate ] = React.useState<string>( fullDate.format( 'MM' ) );
  const [ yearDate, setYearDate ] = React.useState<string>( fullDate.format( 'YYYY' ) );
  const [ selected, setSelected ] = React.useState<Date>( new Date( fullDate.format( formatDatePolicy ) ) );

  const closePopper = React.useCallback ( ( ): void => {
    setOpenDatePicker( false );
    dayPickerRef?.current?.focus();
  }, [] );

  const openDatePicker = React.useCallback ( ( ): void => {
    setOpenDatePicker( !isOpenDatePicker );
  }, [ isOpenDatePicker ] );

  const handleChangePolicyDate = async ( selectedDate ): Promise<void> => {
    const valueDate: dayjs.Dayjs = dayjs( selectedDate );

    setDayDate( valueDate.format( 'DD' ) );
    setMonthDate( valueDate.format( 'MM' ) );
    setYearDate( valueDate.format( 'YYYY' ) );

    const savedDate = valueDate.format( apiDateTimeFormat );

    setValue( fieldName, savedDate, { shouldValidate: true } );
    setSelected( new Date( savedDate ) );

    openDatePicker();

    const isCheckedUnderwriting = checkedUnderwriting( stepItem! );
    const isIgnoreOnRecalculation = checkedRecalculation( stepItem! );
    actions.recalculationPremium( isCheckedUnderwriting, isIgnoreOnRecalculation );
  };

  const addClassChecked = ( errorField: string | undefined, valField: string ): string => {
    if ( errorField === undefined && valField ) {
      return 'dynamic-checked';
    }

    return 'dynamic-date-error';
  };

  return (
    <Fragment>
      <Controller
        name={ fieldName }
        rules={ {
          required: isDisabled ? false : isRequiredField( variable, stepItem ),
        } }
        defaultValue={ dateField }
        render={ ( props ) => (
          <FormGroup
            className={ `${stepItem?.classNameBox!} 
            dynamic-date-box date-picker-field ${ addClassChecked( errors[props.name], props.value ) }` }
          >
            <label id={ `${props.name}-label` }
              className={ `form-label${variable.bfTooltip ? ' tooltip-label' : ''}` }
            >
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
              />
              { variable.bfTooltip && (
                <a
                  id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
                  href={ blankLink }
                  role='button'
                  onClick={ ( e ) => {
                    e.preventDefault();
                    showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
                  } }
                >
                  { t( 'bookingFunnel.tooltipHelp' ) }
                </a>
              ) }
            </label>
            { variable.bfDescription && (
              <span className="gm-info f-12 mt-1">
                { variable.bfDescription }
              </span>
            ) }
            <Row
              className={ `date-container select-container${!props.value ? ' form-select-empty' : '' }` }
            >
              <div
                className="col-md-12 input-group dynamic-input-group"
                ref={ popperRef }
                onClick={ openDatePicker }
              >
                <div className="dynamic-icon-select">
                  <span className="input-group-text"><i className="feather icon-search" /></span>
                </div>
                <Form.Control
                  id="startdate-day"
                  name="day"
                  min="1"
                  max="31"
                  className="dynamic-day"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'TT' }
                  value={ dayDate }
                />
                <Form.Control
                  id="startdate-month"
                  name="month"
                  min="1"
                  max="12"
                  className="dynamic-month"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'MM' }
                  value={ monthDate }
                />
                <Form.Control
                  id="startdate-year"
                  name="year"
                  className="dynamic-year"
                  type="number"
                  readOnly={ true }
                  inputMode="numeric"
                  placeholder={ 'jjjj' }
                  value={ yearDate }
                />
                <span className="icon-checked"></span>
              </div>
              { isOpenDatePicker && (
                <DatePickerComponent
                  popperRef={ popperRef }
                  dayPickerRef={ dayPickerRef }
                  currentValue={ selected }
                  isOpenDatePicker={ isOpenDatePicker }
                  fromDate={ todayDate }
                  toDate={ toDate }
                  closePopper={ closePopper }
                  onChangeDate={ handleChangePolicyDate }
                />
              ) }
            </Row>
            <Form.Control
              { ...props }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <Form.Control.Feedback type="invalid" className="dynamic-error-message">
              { errors[props.name]?.message ? (
                errors[props.name]?.message
              ) : (
                <Fragment>
                  { t( 'base:forms.messages.fieldRequired',
                    { fieldLabel: variable.label } ) }
                </Fragment>
              ) }
            </Form.Control.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
