import { TFunction } from 'i18next';
import { JsonObject, ProtoUtil } from '@cover42/protobuf-util';
import { cloneDeep, isEqual } from 'lodash';
import { isObject } from '../bf-hooks';
import { IPersonalDataStep } from './steps/PersonalDataStep2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContainerFieldItem,
  FormValues,
  IAccountLeadData,
  IBFInsuredObjectItem,
  IBFProductVariable,
  ILeadData,
  IPolicyData,
  IPolicyObject,
  IPolicyObjectPayload,
  IPolicyVariable,
  ITariffDataStep,
  SubStepItem,
} from 'Services/widgets/interfaces';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { apiDateTimeFormat } from 'App/ui-utils';
import { IDataFactorsAndVariables } from './BookingFunnel';
import {
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_BOOL,
  FIELD_TYPE_DATETIME,
} from '../bf-hooks';
import { BaseFields, InsuredObjectTypes, ProductFieldTypeEntity } from 'Services/widgets/enums';
import { JSONPremiumShowsType } from './enum';

dayjs.extend( utc );

const localeList: Record<string, string> = {
  'de': 'de-DE',
  'en': 'en-US',
};


export const formatDefaultDate = 'YYYY-MM-DD';
export const formatInputDate = 'DD.MM.YYYY';
export const billingFrom = dayjs().add( 1, 'day' ).utc().format( apiDateTimeFormat );
export const billingTo = dayjs( billingFrom ).add( 1, 'month' ).utc().format( apiDateTimeFormat );

const fieldPremiumRecalculation = 'ignorePremiumRecalculation';
// This hardcode is only needed for the demo
const travelProductCode = 'prod_HBUCekr--E0NFcK5fr9A_';

export const prepareDataForTariff = (
  data: JsonObject,
  variables: IBFProductVariable[],
  insuredObjects: IBFInsuredObjectItem[],
  isEncodeData: boolean = true,
): IPolicyObject[] => {
  const cloneData = cloneDeep( data );
  const policyObjects: IPolicyObject[] = [];

  insuredObjects.forEach( ( insuredObject ) => {
    const gpoupVariables = variables.filter( ( f ) =>
      f.insuredObjectId === insuredObject.id,
    );

    if ( gpoupVariables.length ) {
      const dataField: JsonObject = {};

      gpoupVariables.forEach( ( field ) => {
        const fieldName = field.name;
        const keyName = `${fieldName}_${insuredObject.name}`;
        const itemValue = cloneData[keyName];

        if ( isObject( itemValue ) && itemValue['key'] ) {
          dataField[fieldName] = itemValue['key'];
        } else {
          if ( itemValue !== undefined ) {
            let fieldValue = itemValue;

            if ( field.typeId === FIELD_TYPE_NUMBER ) {
              fieldValue = Number( fieldValue );
            }

            if ( field.typeId === FIELD_TYPE_BOOL ) {
              fieldValue = Boolean( fieldValue );
            }

            if ( field.typeId === FIELD_TYPE_DATETIME ) {
              if ( fieldName === BaseFields.PolicyStartDate ) {
                fieldValue = dayjs( fieldValue ).format( formatDefaultDate );
              } else {
                fieldValue = dayjs( fieldValue ).toISOString();
              }
            }

            dataField[fieldName] = fieldValue;
          } else {
            let defaultValue = field.defaultValue;

            if ( ( field.typeId === FIELD_TYPE_DATETIME && field.isHidden ) || isObject( defaultValue ) ) {
              const objectDate = defaultValue as JsonObject;
              if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
                defaultValue = objectDate.evaluated as string;
              }
            }

            dataField[fieldName] = defaultValue;
          }
        }
      } );

      const newPolicyObject = {
        insuredObjectId: insuredObject.id,
        data: isEncodeData ? ProtoUtil.encodeStruct( dataField ) : dataField,
      };

      policyObjects.push( newPolicyObject );
    }

  } );

  return policyObjects;
};

export const prepareAccountForInvoice = ( data: JsonObject ): JsonObject => {
  const cloneData = cloneDeep( data );

  Object.keys( cloneData ).forEach( ( fieldName ) => {
    const itemValue = cloneData[fieldName];

    if ( fieldName === 'birthDate' ) {
      if ( itemValue ) {
        cloneData[fieldName] = dayjs( itemValue as string ).format( apiDateTimeFormat );
      }
    }

    if ( isObject( itemValue ) && itemValue['name'] ) {
      cloneData[fieldName] = itemValue['name'];
    }
  } );

  return cloneData;
};

export const prepareAccountData = ( data: IPersonalDataStep ): IAccountLeadData => {
  const newAccountData: IAccountLeadData = {
    gender: 0,
    firstName: '',
    lastName: '',
    zipCode: '',
    city: '',
    street: '',
    houseNumber: '',
    phone: '',
    birthDate: dayjs( new Date() ).format( apiDateTimeFormat ),
    email: '',
  };
  Object.keys( data ).forEach( ( fieldName ) => {
    const itemValue = data[fieldName];

    if ( fieldName === 'birthDate' ) {
      if ( itemValue ) {
        newAccountData[fieldName] = dayjs( itemValue as string ).format( apiDateTimeFormat );
        return;
      }
    }

    if ( isObject( itemValue ) && itemValue['name'] ) {
      newAccountData[fieldName] = itemValue['name'];
    } else {
      newAccountData[fieldName] = itemValue;
    }

  } );

  return newAccountData;
};

const getValueFactorField = (
  lead: ILeadData,
  productData: IDataFactorsAndVariables,
  variable: IBFProductVariable,
) => {

  if ( productData === null ) {
    return;
  }

  const tariffData = { ...lead.subStep1TariffData, ...lead.subStep2TariffData };
  const keyName = `${variable.name}-${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
  const selectedVal = tariffData[keyName];

  if ( isObject( selectedVal ) && selectedVal['name'] ) {
    return selectedVal['name'];
  }

  if ( selectedVal ) {
    const findFactor = productData.factors.find( ( f ) => f.id === variable.typeId )!;

    if ( findFactor ) {
      const factorInfo = findFactor?.values.find( ( f ) => f.key === selectedVal )!;
      return factorInfo?.name;
    }
  }

  return '-';
};

const getValueBaseField = ( lead: ILeadData, variable: IBFProductVariable, t: TFunction ) => {
  const tariffData = { ...lead.subStep1TariffData, ...lead.subStep2TariffData };
  const fieldName = `${variable.name}-${variable.groupName ? variable.groupName : variable.insuredObjectId}`;
  let selectedVal = tariffData[fieldName];

  if ( variable.typeId === FIELD_TYPE_BOOL && selectedVal ) {
    return selectedVal ? t( 'bookingFunnel.tariff.yes' ) : t( 'bookingFunnel.tariff.no' );
  }

  // convert number type to string for correctly view on Django template
  if ( typeof selectedVal === 'number' ) {
    selectedVal = selectedVal.toString();
  }

  return selectedVal ? selectedVal : '-';
};

export const prepareValuesForAplication = (
  lead: ILeadData,
  productData: IDataFactorsAndVariables,
  t: TFunction ): IPolicyData[] => {
  const policyData: IPolicyData[] = [];
  const variableData = productData.variables.filter( ( v ) => !v.isHidden );

  if ( variableData.length > 0 ) {
    variableData.forEach( ( field ) => {
      const foundIndex = policyData.findIndex( ( p ) => p.id === field.insuredObjectId );
      const newItem: IPolicyVariable = {
        label: field.label,
        value: '',
      };

      if ( field.typeEntity === ProductFieldTypeEntity.SystemProductFieldType ) {
        newItem.value = getValueBaseField( lead, field, t );
      }

      if ( field.typeEntity === ProductFieldTypeEntity.ProductFactorType ) {
        newItem.value = getValueFactorField( lead, productData, field );
      }

      if( foundIndex !== -1 ) {
        policyData[foundIndex].variables.push( newItem );
      } else {
        const newPolicyItem: IPolicyData = {
          id: field.insuredObjectId,
          groupName: field.group,
          variables: [ newItem ],
        };

        policyData.push( newPolicyItem );
      }

    } );
  }

  return policyData;
};

export const generateVariables = ( insuredObjects: IBFInsuredObjectItem[] ): IBFProductVariable[] => {
  let variableList: IBFProductVariable[] = [];
  insuredObjects.forEach( ( object ) => {

    if ( object.productFields === undefined ) {
      return;
    }

    object.productFields!.forEach( ( item ) => {
      const newItem = {
        ...item,
        groupName: object.name,
      };
      variableList.push( newItem );
    } );
  } );

  return variableList;
};

export const prepareFormData = ( variables: IBFProductVariable[], formData: ITariffDataStep ): ITariffDataStep => {
  const resForm: ITariffDataStep = cloneDeep( formData );
  const formKeys = Object.keys( resForm );
  formKeys.forEach( ( keyName ) => {
    const variable = variables.find( ( v ) => v.name === keyName );

    if ( variable ) {

      if ( variable.typeId === FIELD_TYPE_BOOL ) {
        resForm[keyName] = Boolean( resForm[keyName] );
      }

      if ( variable.typeId === FIELD_TYPE_NUMBER ) {
        resForm[keyName] = Number( resForm[keyName] );
      }
    }
  } );

  return resForm;
};

export const preparedDefaultGroup = (
  data: JsonObject,
  insuredObjects: IBFInsuredObjectItem[],
  isEncodeData: boolean = true,
): IPolicyObject[] => {
  const cloneData = cloneDeep( data );
  const policyObjects: IPolicyObject[] = [];
  const cloneInsuredObjects = cloneDeep( insuredObjects );
  const defaultGroup = cloneInsuredObjects.find( ( group ) => group.name.toLowerCase() === InsuredObjectTypes.Default );

  if ( defaultGroup && defaultGroup.productFields ) {
    const dataField: JsonObject = {};

    defaultGroup.productFields.forEach( ( item ) => {
      const fieldName = item.name;
      const keyName = `${fieldName}_${defaultGroup.name}`;
      const itemValue = cloneData[keyName];

      if ( itemValue !== undefined ) {
        let fieldValue = itemValue;

        if ( item.typeId === FIELD_TYPE_NUMBER ) {
          fieldValue = Number( fieldValue );
        }

        if ( item.typeId === FIELD_TYPE_BOOL ) {
          fieldValue = Boolean( fieldValue );
        }

        if ( item.typeId === FIELD_TYPE_DATETIME ) {
          fieldValue = dayjs( fieldValue ).toISOString();
        }

        dataField[fieldName] = fieldValue;
      } else {
        let defaultValue = item.defaultValue;

        if ( ( item.typeId === FIELD_TYPE_DATETIME && defaultValue ) || isObject( defaultValue ) ) {
          const objectDate = defaultValue as JsonObject;
          if ( objectDate.hasOwnProperty( 'evaluated' ) ) {
            defaultValue = objectDate.evaluated as string;
          }
        }

        dataField[fieldName] = defaultValue;
      }
    } );

    const newPolicyObject = {
      insuredObjectId: defaultGroup.id,
      data: isEncodeData ? ProtoUtil.encodeStruct( dataField ) : dataField,
    };

    policyObjects.push( newPolicyObject );
  }

  return policyObjects;
};

export const prepareDefaulGroupAplication = (
  policyObjects: IPolicyObject[],
  productData: IDataFactorsAndVariables,
): IPolicyObjectPayload | null => {
  if ( !policyObjects ) {
    return null;
  }
  const defaultGroup = policyObjects[0];
  const cloneData = cloneDeep( defaultGroup.data );
  const insuredObject = productData.insuredObjects.find(
    ( insured ) => insured.id === defaultGroup.insuredObjectId,
  );

  if ( insuredObject && insuredObject.productFields ) {
    const defaultItem: IPolicyObjectPayload = {
      timesliceId: insuredObject.id!,
      insuredObjectName: insuredObject.name,
      insuredObject: insuredObject,
      data: cloneData as JsonObject,
    };

    return defaultItem;
  }

  return null;
};

export const generateBillingTo = ( insuredObjects: IBFInsuredObjectItem[] ): string => {
  const defaultGroup = insuredObjects.find( ( group ) => group.name === InsuredObjectTypes.Default );
  if ( defaultGroup && defaultGroup.productFields ) {
    const productFields = defaultGroup.productFields;
    const billingFrequencyValue = productFields.find( ( field ) => field.name === 'billingFrequencyValue' );
    const billingFrequencyUnit = productFields.find( ( field ) => field.name === 'billingFrequencyUnit' );

    let amount: number | undefined = undefined;

    if ( billingFrequencyValue && billingFrequencyValue.defaultValue ) {
      amount = billingFrequencyValue.defaultValue as number;
    }

    let unit: dayjs.ManipulateType | undefined = undefined;

    if ( billingFrequencyUnit && billingFrequencyUnit.defaultValue ) {
      unit = billingFrequencyUnit.defaultValue as dayjs.ManipulateType;
    }

    if ( amount !== undefined && unit !== undefined ) {
      return dayjs( billingFrom ).add( amount, unit ).utc().format( apiDateTimeFormat );
    }
  }

  return billingTo;
};

export const isIgnorePremiumRecalculation = (
  variables: IBFProductVariable[],
): boolean => {
  const findVariable = variables.find( ( item ) => item.name === fieldPremiumRecalculation );

  if ( findVariable && typeof findVariable.defaultValue === 'boolean' ) {
    return findVariable.defaultValue;
  }

  return false;
};

export const isRequiredField = ( variable: IBFProductVariable, stepItem?: ContainerFieldItem ): boolean => {
  if ( stepItem && 'isRequired' in stepItem && stepItem.isRequired ) {
    return stepItem.isRequired;
  }

  return variable.isRequired;
};

export const isEnabledPremiumCalculation = ( stepItems?: SubStepItem ): boolean => {
  if ( stepItems && 'enabledPremiumCalculation' in stepItems ) {
    return stepItems.enabledPremiumCalculation! as boolean;
  }

  return true;
};

export const getFieldDiff = ( obj1: FormValues, obj2: FormValues ): string => {
  const diff = Object.keys( obj1 ).reduce( ( result, key ) => {
    if ( !obj2.hasOwnProperty( key ) ) {
      result.push( key );
    } else if ( isEqual( obj1[key], obj2[key] ) ) {
      const resultKeyIndex = result.indexOf( key );

      result.splice( resultKeyIndex, 1 );
    }
    return result;
  }, Object.keys( obj2 ) );

  return diff[0];
};

export const isIgnoreOnRecalculationField = ( field: string, stepItems: SubStepItem ): boolean => {
  const items: ContainerFieldItem[] = [];

  stepItems.containers.forEach( ( container ) => {
    items.push( ...container.items );
  } );

  if ( items ) {
    const fieldInfo = field.split( '_' );
    if ( fieldInfo.length >= 2 ) {
      const fieldName = fieldInfo[0];
      const insuredObjectName = fieldInfo[1];

      const findItem = items.find(
        ( item ) => item.fieldName === fieldName && item.insuredObjectName === insuredObjectName,
      );

      if ( findItem && 'ignoreOnRecalculation' in findItem ) {
        return findItem.ignoreOnRecalculation! as boolean;
      }
    }
  }

  return false;
};

export const currencyFormatter = (
  value: number,
  isShowCurrency: boolean = false,
  currency: string = 'EUR',
  locale: string = 'de-DE',
): string => {

  if ( Number( value ) ) {

    const res = new Intl.NumberFormat( locale, {
      style: isShowCurrency ? 'currency' : undefined,
      currency,
    } ).format( value );
    return `${res.trim()}`;
  }

  return '0,00';
};

export const preparePriceValue = ( priceValue: number ): number => {
  if ( priceValue ) {
    return ( priceValue / 100 );
  }

  return 0;
};

export const renderPrice = (
  price: number,
  productCode: string,
  currency?: string,
  locale?: string,
): string => {

  if ( price && productCode ) {
    if ( travelProductCode === productCode ) {
      return `$${( price / 100 ).toFixed( 2 )}`;
    }
  }

  const newPrice = preparePriceValue( price );

  return currencyFormatter( newPrice, true, currency, locale );
};

export const getPremiumShowsType = ( stepItems?: SubStepItem ): JSONPremiumShowsType | undefined => {
  if ( stepItems && 'premiumShows' in stepItems ) {
    return stepItems.premiumShows! as JSONPremiumShowsType;
  }

  return undefined;
};

export const checkedUnderwriting = ( stepItem: ContainerFieldItem ): boolean => {
  if ( stepItem && 'underwritingCriteria' in stepItem ) {
    const isEnabled = ( 'enabled' in stepItem.underwritingCriteria! )
      ? stepItem.underwritingCriteria.enabled : false;

    return isEnabled;
  }

  return false;
};

export const useLocale = (): string => {
  const { i18n } = useTranslation();
  const locale = React.useMemo( () => {
    const lng = i18n.language;
    const currentLocale = localeList[lng];
    if ( currentLocale ) {
      return currentLocale;
    }
    // Set German localization for dashboard by default
    return localeList['de'];
  }, [ i18n.language ] );
  return locale;
};

export const checkedRecalculation = ( stepItem: ContainerFieldItem ): boolean => {
  if ( stepItem && 'ignoreOnRecalculation' in stepItem ) {
    return stepItem.ignoreOnRecalculation! as boolean;
  }

  return false;
};
