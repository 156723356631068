import React, { Fragment } from 'react';
import { blankLink } from 'config';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IBFProductVariable, ContainerFieldItem } from 'Services/widgets/interfaces';
import { JsonValue } from '@cover42/protobuf-util';
import {
  checkedRecalculation,
  checkedUnderwriting,
  isRequiredField,
} from '../../../booking-funnel/booking-funnel-hooks';
import { useCoreActions } from '../../DynamicCore';
import { FieldDefaultValue } from 'App/widget/types';
import { Separators } from 'Services/widgets/enums';
import { TRUE_VALUE } from 'App/components/widgets/bf-hooks';
import { renderFieldLabel } from '../../core-hooks';

export interface RadioFieldProps {
  valueField: boolean | undefined;
  variable: IBFProductVariable;
  showTooltip: ( isShow: boolean, tooltip: string, target?: React.ReactInstance ) => void;
  isShortName?: boolean;
  showAsterisk?: boolean;
  stepItem?: ContainerFieldItem;
  fieldDefaultValue?: FieldDefaultValue;
  isDisabled?: boolean;
}

interface RadioOption {
  value: string;
  label: string;
}

export const RadioField: React.FC<RadioFieldProps> = ( {
  valueField,
  variable,
  showTooltip,
  isShortName,
  stepItem,
  fieldDefaultValue,
  isDisabled,
} ) => {
  const { t } = useTranslation( [ 'widgets', 'base' ] );
  const actions = useCoreActions();
  const { errors, control, setValue, getValues } = useFormContext();
  const { productFieldValue } = fieldDefaultValue || {};
  let fieldName = isShortName ? variable.name :
    `${variable.name}_${variable.groupName ? variable.groupName : variable.insuredObjectId}`;

  if ( stepItem && stepItem.groupName ) {
    fieldName = `${fieldName}_${stepItem.groupName}`;
  }

  const radioOptions: RadioOption[] = [
    { value: 'true', label: t( 'bookingFunnel.tariff.yes' ) },
    { value: 'false', label: t( 'bookingFunnel.tariff.no' ) },
  ];

  const defaultValueSC = stepItem && stepItem.defaultValue ? String( stepItem.defaultValue ) : '';

  const getDefaultValue = ( boolValue: JsonValue ): boolean => {
    if ( productFieldValue ) {
      return productFieldValue as boolean;
    }

    if ( defaultValueSC ) {
      return defaultValueSC.toLowerCase() === TRUE_VALUE;
    }

    return boolValue !== null ? boolValue as boolean : false;
  };

  const handleChange = React.useCallback( (
    e: React.ChangeEvent<HTMLInputElement>,
    renderProps: ControllerRenderProps<Record<string, any>>,
  ) => {
    renderProps.onChange( e.target.value );

    if ( stepItem && stepItem.groupName ) {
      const formValues = getValues( );
      const groupName = stepItem.groupName;

      Object.keys( formValues ).forEach( ( keyName ) => {
        const currentFieldName = e.target.name;
        if ( currentFieldName !== keyName && keyName.endsWith( `${Separators.Underscore}${groupName}` ) ) {
          setValue( keyName, false, { shouldValidate: true } );
        }
      } );
    }

    if ( stepItem ) {
      const isCheckedUnderwriting = checkedUnderwriting( stepItem );
      const isIgnoreOnRecalculation = checkedRecalculation( stepItem );
      actions.recalculationPremium( isCheckedUnderwriting, isIgnoreOnRecalculation );
    }
  }, [ actions, getValues, setValue, stepItem ] );

  const isReadOnlyInput = (): boolean => {
    if ( stepItem && stepItem.isReadOnly ) {
      return stepItem.isReadOnly;
    }

    return false;
  };

  React.useEffect( () => {
    if ( stepItem && isDisabled && defaultValueSC ) {
      setValue( fieldName, defaultValueSC.toLowerCase() === TRUE_VALUE, { shouldValidate: true } );
    }
  }, [ defaultValueSC, fieldName, isDisabled, setValue, stepItem ] );

  return (
    <Fragment>
      <Form.Label className={ variable.bfTooltip && 'tooltip-label' }>
        <div
          className="d-inline-block"
          dangerouslySetInnerHTML={ { __html: `${renderFieldLabel( variable, stepItem )}` } }
        />
        { variable.bfTooltip && (
          <a
            className="tooltip-info"
            id={ `tooltip-${variable.name}-${variable.insuredObjectId}` }
            href={ blankLink }
            role='button'
            onClick={ ( e ) => {
              e.preventDefault();
              showTooltip( true, variable.bfTooltip!, e.target as unknown as React.ReactInstance );
            } }
          >
            { t( 'bookingFunnel.tooltipHelp' ) }
          </a>
        ) }
      </Form.Label>
      { variable.bfDescription && (
        <span className="gm-info f-12 mt-1">
          { variable.bfDescription }
        </span>
      ) }
      <Controller
        name={ fieldName }
        control={ control }
        rules={ {
          required: isDisabled ? false : isRequiredField( variable, stepItem ),
          validate: ( value ) => {
            if ( isDisabled ) {
              return true;
            }
            if ( value === null ) {
              return false;
            }
            return true;
          },
        } }
        defaultValue={ valueField ? valueField : getDefaultValue( variable.defaultValue ) }
        render={ ( props ) => (
          <FormGroup className={ `custom-btn-radio mb-2 rdb-${props.name} btn-col-2` }>
            { radioOptions.map( ( o, idx ) => {
              return (
                <div className="box-radio" key={ idx }>
                  <Form.Control
                    { ...props }
                    className="form-check-input"
                    type="radio"
                    disabled={ isDisabled || isReadOnlyInput() }
                    id={ `option_${ props.name + '_' + idx}` }
                    value={ o.value }
                    checked={ String( props.value ) === String( o.value ) }
                    onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => handleChange( e, props ) }
                    onBlur={ props.onBlur }
                  />
                  <Form.Label
                    className="btn btn-border-radio"
                    htmlFor={ `option_${ props.name + '_' + idx}` }
                  >
                    { o.label }
                  </Form.Label>
                </div>
              );
            } ) }
            <Form.Control
              id={ `error-messages-${props.name}` }
              type="hidden"
              isInvalid={ errors[props.name] !== undefined }
            />
            <FormControl.Feedback type="invalid">
              { t( 'base:forms.messages.fieldRequired', {
                fieldLabel: variable.label,
              } ) }
            </FormControl.Feedback>
          </FormGroup>
        ) }
      />
    </Fragment>
  );
};
